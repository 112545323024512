import React, { Suspense, lazy } from 'react';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';

import AuthProvider from './hook/AuthContext';

// load Layouts
const RootLayout = lazy(() => import('./components/Layouts/RootLayout'));

const router = createBrowserRouter([
  {
    path: "/",
    loader: async () => {
      return null
    },
    element: <RootLayout />,
    children: [
      { index: true, Component: () => (<h1>Select Portal form here</h1>) },
      {
        path: ":portalID", lazy: () => import("./components/portal/root"),
        children: [
          { index: true, Component: () => (<h1>Dashboard</h1>), },
          { path: ":pageID",  lazy: () => import("./components/portal/page"), },
          { path: "T/:pageID",  lazy: () => import("./components/portal/layout/table"), },
          { path: "TT/:pageID", lazy: () => import("./components/portal/layout/table2"), }
        ]
      },
      {
        path: 'my', lazy: () => import('./components/my/root'),
        children: [
          { path: 'profile', Component: () => (<h2>Profile</h2>) },
          { path: 'portals', index: true, lazy: () => import('./components/my/portals') },
          { path: 'settings', element:(<h2>Settings</h2>) },
        ]
      },
    ],
  },
  {
    path: "/auth",
    lazy: () => import("./services/authService")
  }

]);


const App = () => {
  return (
    <AuthProvider>
      <Suspense fallback={<><div>Loading(Suspence in App.js)...</div><Outlet /></>}>
        <RouterProvider router={router} />
      </Suspense>
    </AuthProvider>
  );
};

export default App;
