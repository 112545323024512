import React, { createContext, useState, useEffect } from 'react';
import api_req from '../services/apiService'

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(null)

  const getAuth = async () => {
    const res = await api_req('/r/auth', null);
    if (res.hasOwnProperty('auth')) {
      setAuth(res.auth)
    }
  }

  useEffect(() => {
    getAuth()
    /*
    console.log('document.cookie', document.cookie)
    const cookies = `; ${document.cookie}`;
    const parts = cookies.split(`; authToken=`);
    if (parts.length === 2) {
      setAuthToken(parts.pop().split(';').shift())
    } else {
      setAuthToken(null)
    }
    */
  }, []);

  const logout = async () => {
    await api_req('/r/auth/logout', null);
    setAuth(null)
  };

  return (
    <AuthContext.Provider value={{ auth, setAuth, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
