const { redirect } = require("react-router-dom");
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL //|| 'https://3vcjux5k4e.execute-api.ap-southeast-2.amazonaws.com/api'

module.exports = async (url = '', data = {}, method = 'POST') => {
    console.log('api_req Start >>', url, data, method)
    const res = await fetch(`${API_BASE_URL}${url}`, {
        method: method, // *GET, POST, PUT, DELETE, etc.
        credentials: 'include', // include cookies in the request
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    console.log('api_req End >>', url, data, method, res)
    if (!res.ok) {
        return {} 
        //const message = `An error has occurred: ${res.status}`;
        //throw new Error(message);
    }
    const parsed_res = res.json();
    if(parsed_res.hasOwnProperty(redirect)){
        redirect(parsed_res.redirect)
    }
    return parsed_res;
}